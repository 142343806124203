import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    
    name: 'Home',
    component: () => import(/* webpackChunkName: "exhibitor-overview" */ '../views/Home.vue')
  },
  {
    path: '/exhibitor-overview/:tags?/:query?/:districts?/:options?',
    alias: '/ausstellerkatalog/:tags?/:query?/:districts?/:options?',
    name: 'Ausstellerübersicht',
    component: () => import(/* webpackChunkName: "exhibitor-overview" */ '../views/ExhibitorOverview.vue')
  },
  {
    path: '/exhibitor/:id',
    alias: '/aussteller/:id',
    name: 'Aussteller',
    component: () => import(/* webpackChunkName: "exhibitor" */ '../views/Exhibitor.vue')
  },
  {
    path: '/imprint',
    alias: '/impressum',
    name: 'Impressum',
    component: () => import(/* webpackChunkName: "imprint" */ '../views/Imprint.vue')
  },
  {
    path: '/privacy',
    alias: '/datenschutz',
    name: 'Datenschutzerklärung',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue')
  },
  {
    path: '/condition_of_participation',
    alias: '/teilnahmebedingungen',
    name: 'Teilnahmebedingungen',
    component: () => import(/* webpackChunkName: "condition_of_participation" */ '../views/ConditionOfParticipation.vue')
  },
  {
    path: '/for-media',
    alias: '/pressebereich',
    name: 'für Medien',
    component: () => import(/* webpackChunkName: "for-media" */ '../views/ForMedia.vue')
  },
  {
    path: '/for-exhibitors',
    alias: '/ausstellerbereich',
    name: 'Für Aussteller*innen',
    component: () => import(/* webpackChunkName: "for-exhibitors" */ '../views/ForExhibitors.vue')
  },
  {
    path: '/consulting',
    alias: '/beratung',
    name: 'Beratung',
    component: () => import(/* webpackChunkName: "consulting" */ '../views/Consulting.vue')
  },
  {
    path: '/contact',
    alias: '/kontakt',
    name: 'Kontakt',
    component: () => import(/* webpackChunkName: "archive" */ '../views/Contact.vue')
  },
  {
    path: '/archive',
    name: 'Archiv',
    component: () => import(/* webpackChunkName: "archive" */ '../views/Archive.vue')
  },
  {
    path: '/people-with-disabilities',
    name: 'Menschen mit Einschränkungen',
    component: () => import(/* webpackChunkName: "people-with-disabilities" */ '../views/PeopleWithDisabilities.vue')
  },
  {
    path: '/blog-overview',
    name: 'Blogübersicht',
    component: () => import(/* webpackChunkName: "blog-overview" */ '../views/BlogOverview.vue')
  },
  {
    path: '/blog/:id',
    name: 'Blog',
    component: () => import(/* webpackChunkName: "blog" */ '../views/Blog.vue')
  },
  {
    path: '/sonder-registrierung',
    /*alias: '/aussteller-registrierung', */
    name: 'Aussteller Registrierung',
    component: () => import(/* webpackChunkName: "sonder-registrierung" */ '../views/ExhibitorRegistration.vue')
  },
  {
    path: '/info-videos',
    name: 'InfoVideos',
    component: () => import(/* webpackChunkName: "info-videos" */ '../views/InfoVideos.vue')
  },
  {
    path: '/project-videos',
    name: 'ProjektVideos',
    component: () => import(/* webpackChunkName: "project-videos" */ '../views/ProjectVideos.vue')
  },
  {
    path: '/current-videos',
    name: 'AktuelleVideos',
    component: () => import(/* webpackChunkName: "project-videos" */ '../views/CurrentVideos.vue')
  },
  {
    path: '/instagram',
    name: 'Instagram',
    component: () => import(/* webpackChunkName: "instagram" */ '../views/Instagram.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {x: 0, y: 0}
  }
})

export default router
