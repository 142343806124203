<template>
  <v-app>
    <v-app-bar class="nav" app flat height="80">
      <v-container class="pa-0 fill-height">
        <router-link to="/">
          <img src="@/assets/logo_ohne_claim.png" class="logo" />
        </router-link>
        <v-spacer />
        <!--<router-link to="/people-with-disabilities">
          <img src="@/assets/signet-leichte-sprache.png" class="easy-language"/>
        </router-link>
        -->
        <v-btn class="pa-1 pa-sm-2" to="/" :x-small="$vuetify.breakpoint.smAndDown" text>Home</v-btn>
        <!--<v-btn class="pa-1 pa-sm-2" @click="showHallPlan = !showHallPlan" :x-small="$vuetify.breakpoint.smAndDown" text>Lageplan</v-btn>-->
        <!--<v-btn class="pa-1 pa-sm-2" to="/consulting" :x-small="$vuetify.breakpoint.smAndDown" text>Beratung</v-btn>-->
        <!--<v-btn to="/aussteller-registrierung" :x-small="$vuetify.breakpoint.smAndDown" text>Aussteller*innen Registrierung</v-btn>-->
        <!--<v-btn class="pa-1 pa-sm-2" to="/ausstellerkatalog" :x-small="$vuetify.breakpoint.smAndDown" text> Ausstellende</v-btn>-->
        <v-btn class="pa-1 pa-sm-2" to="/archive" :x-small="$vuetify.breakpoint.smAndDown" text>Archiv</v-btn>
        <!--<v-btn to="/blog-overview" :x-small="$vuetify.breakpoint.smAndDown" text>Blog</v-btn>-->
      </v-container>
    </v-app-bar>

    <v-main class="grey lighten-3">
      <v-container style="height: 100%">
        <v-sheet height="100%" max-width="1200px">
          <keep-alive>
            <router-view/>
          </keep-alive>
        </v-sheet>
      </v-container>
      
      <!--<v-btn @click="showHallPlan = true" elevation="2" fixed fab dark style="right: 10px; bottom: 6%"><v-icon>mdi-map</v-icon></v-btn>-->
      <v-dialog v-model="showHallPlan" width="90%" height="100%">
        <v-carousel v-if="$vuetify.breakpoint.mobile" height="100%">
          <v-carousel-item src="@/assets/Lageplan-2024-A.png" />
          <v-carousel-item src="@/assets/Lageplan-2024-B.png" />
        </v-carousel>
        <v-carousel v-else height="100%" :show-arrows="false" :hide-delimiters="true">
          <v-carousel-item src="@/assets/Lageplan-2024.png" />
        </v-carousel>
      </v-dialog>
      
    </v-main>

    <v-footer class="footer">
      <v-row>
        <v-col class="text-center"><v-btn to="/pressebereich" text>Medien</v-btn></v-col>
        <v-col class="text-center"><v-btn to="/ausstellerbereich" text>Für Aussteller*innen</v-btn></v-col>
        <!--<v-col class="text-center"><v-btn to="/archive" text>Archiv</v-btn></v-col>-->
        <v-col class="text-center"><v-btn to="/datenschutz" text>Datenschutz</v-btn></v-col>
        <v-col class="text-center"><v-btn to="/impressum" text>Impressum</v-btn></v-col>
        <v-col class="text-center"><v-btn to="/teilnahmebedingungen" text>Teilnahmebedingungen</v-btn></v-col>
        <v-col class="text-center"><v-btn to="/kontakt" text>Kontakt</v-btn></v-col>
        <v-col class="text-center">
          <v-btn text href="https://www.facebook.com/AktivoliHamburg" target="_blank">
            <v-icon color="primary">mdi-facebook</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
  export default {
    name: 'App',
    data: function () {
      return {
        showHallPlan: false
      }
    }
  }
</script>

<style lang="scss">

  @import "@/assets/_variables.scss";

  html,body {
    .v-application {
      font-family: 'Open Sans', sans-serif;
    }
  }

  .aktivoli-gray {
    background-color: #F5F5F5 !important;
  }

  h1 {
    font-weight: normal;
    font-size: 2.1em;
  }

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.17em;
  }

  h4 {
    font-weight: bold;
    font-size: 1.3em;
  }

  p {
    font-size: 1em;
    line-height: 1.875em;

  }

  .nav, .footer {
    background-color: #fad858 !important;
    
    button span {
      font-weight: bold;
      color: #D00B3C;
    }

    a span {
      font-weight: bold;
      color: #D00B3C;
    }

    .v-btn::before{
      background-color:transparent;
    }
  }

  .directus-wysiwyg-content {
    //border: solid 1px red;
    margin-top: 20px;

    ul {
      margin-bottom: 16px;
    }

    p {
      margin-left: 10px
    }

    blockquote {
      padding: 16px 0 16px 24px;
      background-color: #ffffff;
      border-left: solid 2px #546e7a;
      font-style: italic;
    }
  }

  .logo {
    max-width:70px;
    height: 70px;
  }

  .easy-language {
    max-width: 44px;
    height: 44px;
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    .logo {
      max-width:40px;
      height: 40px;
    }

    .easy-language {
      max-width: 30px;
      height: 30px;
    }
  }

</style>