import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'

Vue.config.productionTip = false

import { Directus } from "@directus/sdk";
const client = new Directus("https://directus.aktivoli-boerse.de")

Object.defineProperties(Vue.prototype, {
  $client: { value: client }
})

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    favorites: [],
    instagramPermission: false
  },
  mutations: {
    initialiseStore(state) {
      // load local storage if found
      if (localStorage.getItem('store')) {
        this.replaceState(
          Object.assign(state, JSON.parse(localStorage.getItem('store')))
        )
      }
    },
    toggleFavorit(state, favorit) {
      let index = state.favorites.indexOf(favorit)
      if (index > -1) { // found -> delete
        state.favorites.splice(index, 1)
      } else { // not found -> save
        state.favorites.push(favorit)
      }
    },
    toggleInstagramPermission(state, status) {
      console.log(status)
      state.instagramPermission = status
    }
  },
  getters: {
  }
})

store.subscribe((mutation, state) => {
	// Store the state object as a JSON string
	localStorage.setItem('store', JSON.stringify(state));
});

// helper functions
const plugins = {
  install() {
    Vue.prototype.$getContrastColorByColor = (color) => {
        var contrastColor = (color.charAt(0) === '#') ? color.substring(1, 7) : color;
        var r = parseInt(contrastColor.substring(0, 2), 16); // hexToR
        var g = parseInt(contrastColor.substring(2, 4), 16); // hexToG
        var b = parseInt(contrastColor.substring(4, 6), 16); // hexToB
        var uicolors = [r / 255, g / 255, b / 255];
        var c = uicolors.map((col) => {
          if (col <= 0.03928) {
            return col / 12.92;
          }
          return Math.pow((col + 0.055) / 1.055, 2.4);
        });
        var L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
        return (L > 0.179) ? '#000000' : '#FFFFFF';
      }
  }
}

Vue.use(plugins)

new Vue({
  vuetify,
  router,
  store,
  beforeCreate() {
		this.$store.commit('initialiseStore')
	},
  render: h => h(App)
}).$mount('#app')
